<template>
  <div class=" font-poppins h-full ">
    <router-view/>
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent";

export default {
  setup() {

  },
  components: {
    HeaderComponent
  },
  mounted() {
    document.title = "Jobs Nexah"
  }
}
</script>


<style>
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap');
</style>
