<!--
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
  <div>
    <div class="mt-1 relative flex space-x-2">
      <div class="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
        <slot class="">
          <!--          <SearchIcon class="h-6 w-6 text-gray-300" aria-hidden="true" />-->
        </slot>
      </div>
      <input disabled type="email" v-on:input="getValueForm" v-model="state.jobTitle" name="email" id="email"
             class="block pl-10 h-14 text-gray-500 placeholder-gray-300 focus:ring-0 lg:tracking-widest md:tracking-normal lg:w-72 md:text-xl sm:text-sm border-none "
             :placeholder="state.placeholder"/>
    </div>
  </div>
</template>

<script>
import {MailIcon, SearchIcon} from '@heroicons/vue/outline'
import {reactive, watch} from "vue";

export default {
  props: ['placeholder', 'jobTitle'],
  emits: ['getValueForm'],
  setup(props, {emit}) {
    const state = reactive({
      placeholder: props.placeholder,
      jobTitle: props.jobTitle
    })

    function getValueForm() {
      emit('getValueForm', state.jobTitle)
    }

    return {
      state,
      getValueForm
    }
  },
  components: {
    MailIcon,
    SearchIcon
  },
}
</script>