import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import detailOffre from "@/views/detailOffre";
import SpontaneousApplicationView from "@/views/SpontaneousApplicationView";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/detail:id',
    name: 'detail',
    component: detailOffre
  },
  {
    path: '/spontaneous',
    name: 'spontaneous',
    component: SpontaneousApplicationView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
