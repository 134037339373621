<template>
  <div class="container w-full">
    <div class="md:w-1/3 xs:w-full mx-auto">
      <div class="bg-white rounded-lg ring-1 ring-blue-400 shadow-lg">
        <div class="w-auto border-t-8 border-blue-400 rounded-lg flex">
          <div class="w-1/3 pt-6 flex justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" class="fill-current text-blue-400" viewBox="0 0 20 20" fill="currentColor">
              <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z" />
            </svg>
          </div>
          <div class="w-full pt-9 pr-4">
            <h3 class="font-bold text-blue-400">{{ message }}</h3>
            <p class="py-4 text-sm text-gray-400">{{description}}</p>
          </div>
          <div class="flex">
            <svg @click="closeDialog" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mt-5 mr-5 text-red-400 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" >
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
            </svg>
          </div>
        </div>

        <div class="p-4 flex space-x-4">
          <a v-if="clickPositif" @click="clikPos()" href="#" class="w-full px-4 py-3 text-center bg-gray-100
         text-gray-400 hover:bg-gray-200 hover:text-black font-bold
          rounded-lg text-sm focus:bg-gray-200 focus:ring-2 focus:ring-pink-300">{{ clickPositif }}</a>

          <a v-if="clickNegatif" @click="clickNeg()" href="#" class="w-1/2 px-4 py-3 text-center text-pink-100
        bg-pink-600 rounded-lg hover:bg-pink-700 hover:text-white
        font-bold text-sm focus:ring-2 focus:ring-pink-600">{{ clickNegatif }}</a>
        </div>
      </div>
    </div>

    <!--Loader-->
  </div>


</template>

<script>
export default {
  name: "DialogCard",
  data(){
    return{
    }
  },
  components:{
  },
  props : ["message","description", "clickPositif", "clickNegatif"],
  methods:{
    clikPos(){
      this.$emit("clikPos")
      // this.loading = true
    },
    clickNeg(){
      this.$emit("clickNeg", false)
    },
    closeDialog(){
      this.$emit("clickNeg", false)
    }
  }
}
</script>

<style scoped>
</style>