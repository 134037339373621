<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <header v-bind:style="{background:'#F7F9FD'}" v-bind:class="hauteur?hauteur:''" class=" shadow-md z-10">
    <nav class="mx-auto px-4 sm:px-6 lg:px-12" aria-label="Top">
      <div class="w-full py-6 flex items-center justify-between border-b border-indigo-500 lg:border-none">
        <div class="flex items-center">
<!--          <a href="#">-->
<!--            <span class="sr-only">NEXAH CV</span>-->
<!--            <CubeTransparentIcon  class="h-8 w-8 text-red-500"/>-->
<!--          </a>-->
          <div class="ml-2 space-x-8 lg:block">
            <router-link to="/">
            <img
                 src="https://nexah.net/public/img/logos/logo-dark.png"
                 alt="">
            </router-link>
          </div>
        </div>
        <div class="ml-10 space-x-4">
          <a href="https://nexah.net/" target="_blank" type="button" class="inline-flex bg-gray-500 items-center px-4 py-3 border border-transparent
          shadow-sm text-sm leading-4 font-medium rounded text-white border-none
          focus:outline-none focus:ring-2  focus:ring-offset-2 focus:ring-blue-500 xs:text-xs xs:hidden xs:px-1.5 ">
            <div>Site web</div>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1" viewBox="0 0 20 20" fill="currentColor">
              <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
              <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
            </svg>
          </a>
          <button @click="spontaneous" type="button" class="inline-flex bg-blue-500 items-center px-4 py-3 border
           border-transparent shadow-sm text-sm leading-4 font-medium rounded text-white
           border-none focus:outline-none focus:ring-2 focus:ring-offset-2
           focus:ring-indigo-500  sm:inline-flex xs:text-xs xs:px-1.5">
            Candidature spontanée
          </button>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import {PlusSmIcon,CubeTransparentIcon} from "@heroicons/vue/solid"
import cv from "../../src/assets/embauche.png"
const navigation = [
  { name: 'NEXAH', href: ' #' }
]

export default {
  props : ['hauteur'],
  setup() {
    return {
      navigation,
      cv,
    }
  },
  methods:{
    spontaneous(){
      this.$router.push({'name':'spontaneous'})
      // this.$emit('bientot_disponible')
    },
    redirect(){
      // window.location = 'https://nexah.net/'
    }
  },
  components:{
    PlusSmIcon,
    CubeTransparentIcon
  }
}
</script>