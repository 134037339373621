<template>
  <div class="px-12 ">
    <div class="flex items-center bg-white w-full
    m-auto rounded-lg shadow-xl-blue lg:justify-between lg:flex-row xs:flex-col
    xs:justify-center">
      <div class="flex justify-between lg:space-x-10 xl:space-x-64 md:flex-row xs:flex-col sm:flex-row items-center pb-2 pt-2 pl-4 pr-4">
        <div class="">
          <InputResearchComponent  v-on:getValueForm="getValueForm" :jobTitle="state.jobTitle" :placeholder="'Titre de l\'emploi'">
            <SearchIcon class="h-6 w-6 text-gray-500" aria-hidden="true" />
          </InputResearchComponent>
        </div>
        <div class="flex justify-center items-center">
          <hr class="xs:hidden h-8 w-0.5 mr-3 bg-gray-200">
          <InputResearchComponent :placeholder="'Emplacement'">
            <LocationMarkerIcon class="h-6 w-6 text-gray-500" aria-hidden="true" />
          </InputResearchComponent>
        </div>
      </div>
      <div class="px-8  container justify-end  xs:mb-4 xs:justify-center lg:mb-0 ">
        <button  v-on:click="research(state.jobTitle)" type="button" class="bg-blue-500 flex items-center
        justify-center px-16 py-2 h-12
        tracking-widest border border-transparent shadow-sm
        text-sm leading-4 font-medium rounded text-white
         border-none focus:outline-none focus:ring-2
         focus:ring-offset-2 focus:ring-indigo-500 xs:max-w-2xl lg:max-w-xs block w-full">
          <div>Rechercher</div>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import InputResearchComponent from "@/components/InputResearchComponent";
import { SearchIcon, LocationMarkerIcon } from '@heroicons/vue/outline'
import {reactive, onMounted} from "vue";

export default {
  name: "ResearchComponent",
  props:['jobTitle'],
  emits: ['getValueForm'],
  setup({jobTitle},{emit}){
   const state = reactive({
      jobTitle:jobTitle
    })

    function research(find) {
     // console.log(find)
      // state.datas = state.datas.filter((fd)=> fd.name.includes(find))
    }

    function getValueForm(e){
     emit('getValueForm',e)
    }

    onMounted(()=>{
      console.log(state.jobTitle)
    })

    return {
     state,
      research,
      getValueForm
    }
  },
  components: {
    InputResearchComponent,
    SearchIcon,
    LocationMarkerIcon
  }
}
</script>

<style scoped>

</style>