<template>
  <div>
    <HeaderComponent v-on:bientot_disponible="showDialog2=true" :hauteur="'h-20'"/>

    <LoaderComponent class="m-auto mt-20" v-if="dataDetail == null"/>

    <div v-if="dataDetail !== null" class="flex h-auto md:flex-row xs:flex-col">
      <div class="flex-1 fontnexah h-full w-full lg:px-8 xs:px-2 mt-1 ">
        <div>
          <h1 class="text-xl font-bold text-center mt-8">
            {{ dataDetail.name }}
          </h1>
          <p class="mt-4">
            {{ dataDetail.description }}
          </p>
        </div>


        <div class="mt-10">
          <h2 class="text-lg font-bold">Plus value</h2>
          <p class="mt-2">
            {{ dataDetail.capital_gain }}
          </p>

          <h2 class="text-lg font-bold mt-2">Pourquoi nous choisir ?</h2>
          <p class="mt-2">
            {{ dataDetail.reason }}
          </p>

          <h2 class="text-lg font-bold mt-2">Missions</h2>
          <p class="mt-2">
            {{ dataDetail.title_mission }}
          </p>

          <ul class="mt-4">
            <li class="ml-10 mb-1 list-disc" v-for="(data,index) in listMission" :key="index">
              {{ data }}
            </li>
          </ul>
        </div>


        <div class="mt-4">
          <h2 class="font-bold text-lg">Profil</h2>
          <ul class="mt-2">
            <li v-for="(data, index) in listProfil" :key="index" class="ml-10 list-disc">
              {{ data }}
            </li>
          </ul>
        </div>


        <div class="mt-4">
          <h2 class="font-bold text-lg mt-3">Compétences personnelles</h2>
          <ul class="mt-2">
            <li v-for="(data, index) in listCompetence" :key="index" class="ml-10 list-disc">
              {{ data }}
            </li>
          </ul>
        </div>

        <div class="mt-4">
          <h2 class="font-bold text-lg">Autres informations</h2>
          <table class="border mt-4 border-2 border-black mb-6 xs:w-full">
            <tr class="border-b border-gray-400">
              <td class="p-1 w-1/2">Type de contrat</td>
              <th class="p-1 w-1/2 text-left border-l">CDI</th>
            </tr>

            <tr class="border-b border-gray-400">
              <td class="p-1">Localisation du poste</td>
              <th class="p-1 border-l text-left">{{ dataDetail.town }}</th>
            </tr>

            <tr>
              <td class="p-1">Date limite</td>
              <th class="p-1 border-l text-left">{{ dataDetail.deadline }}</th>
            </tr>
          </table>
        </div>
      </div>

      <div class="flex-1 h-full p-5 mt-1 shadow-2xl">
        <form enctype="multipart/form-data" v-on:submit.prevent="submit" class="space-y-8 divide-y divide-gray-200">
          <div class="space-y-8 divide-y divide-gray-200">
            <div class="pt-8">
              <div>
                <div class="flex justify-between">
                  <dib>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Soumettre Votre Candidature</h3>
                  </dib>
                  <div v-if="dataDetail.expire===0">
                    <h3 class="bg-red-500 px-6 py-0.5 text-sm rounded-md text-white">
                      Cette offre a expiré
                    </h3>
                  </div>
                </div>

                <p class="mt-1 text-sm text-gray-500">Veuillez remplir tous les champs obligatoires (*)</p>
              </div>
              <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div class="sm:col-span-3">
                  <label for="name" class="block text-sm font-medium text-gray-700"> Noms *</label>
                  <div class="mt-1">
                    <input type="text" v-model="formData.name" required name="name" id="name" autocomplete="family-name"
                           class="shadow-sm focus:ring-blue-500 focus:border-blue-400 block w-full sm:text-sm border-gray-300 rounded-md"/>
                  </div>
                </div>
                <div class="sm:col-span-3">
                  <label for="last_name" class="block text-sm font-medium text-gray-700"> Prénoms *</label>
                  <div class="mt-1">
                    <input type="text" v-model="formData.last_name" required name="name" id="last_name"
                           class="shadow-sm focus:ring-blue-500 focus:border-blue-400 block w-full sm:text-sm border-gray-300 rounded-md"/>
                  </div>
                </div>

                <div class="sm:col-span-3">
                  <label for="age" class="block text-sm font-medium text-gray-700"> Date de naissance * </label>
                  <div class="mt-1">
                    <input type="date" v-model="formData.age" required name="age" id="age" autocomplete="on"
                           class="shadow-sm focus:ring-blue-500 focus:border-blue-400 block w-full sm:text-sm border-gray-300 rounded-md"/>
                  </div>
                </div>


                <div class="sm:col-span-3">
                  <label for="email" class="block text-sm font-medium text-gray-700"> Email *</label>
                  <div class="mt-1">
                    <input v-model="formData.email" required type="email" name="email" id="email"
                           autocomplete="email"
                           class="shadow-sm focus:ring-blue-500 focus:border-blue-400 block w-full sm:text-sm border-gray-300 rounded-md"/>
                  </div>
                </div>


                <div class="sm:col-span-3">
                  <label for="phone" class="block text-sm font-medium text-gray-700"> Téléphone *</label>
                  <div class="mt-1">
                    <input v-model="formData.phone" type="number" required name="phone" id="phone" autocomplete="tel"
                           class="shadow-sm focus:ring-blue-500 focus:border-blue-400 block w-full sm:text-sm border-gray-300 rounded-md"/>
                  </div>
                </div>

                <div class="sm:col-span-3">
                  <label for="phone2" class="block text-sm font-medium text-gray-700">Téléphone 2 </label>
                  <div class="mt-1">
                    <input v-model="formData.phone2" type="number" name="phone2" id="phone2" autocomplete="tel"
                           class="shadow-sm focus:ring-blue-500 focus:border-blue-400 block w-full sm:text-sm border-gray-300 rounded-md"/>
                  </div>
                </div>


                <div class="sm:col-span-3">
                  <label for="experience_year" class="block text-sm font-medium text-gray-700"> Années
                    d'expérience * </label>
                  <div class="mt-1">
                    <input v-model="formData.experience_year" type="number" required name="experience_year"
                           id="experience_year"
                           class="shadow-sm focus:ring-blue-500 focus:border-blue-400 block w-full sm:text-sm border-gray-300 rounded-md"/>
                  </div>
                </div>

                <div class="sm:col-span-3">
                  <label for="sexe" class="block text-sm font-medium text-gray-700">Sexe *</label>
                  <select v-model="formData.sexe" id="sexe" required name="sexe"
                          class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-400 sm:text-sm rounded-md">
                    <option value="Masculin" name="Masculin">Masculin</option>
                    <option value="Feminin" name="Feminer">Féminin</option>
                  </select>
                </div>

                <div class="sm:col-span-3">
                  <label for="town" class="block text-sm font-medium text-gray-700"> Ville *</label>
                  <div class="mt-1">
                    <input v-model="formData.town" required type="text" name="town" id="town"
                           autocomplete="address-level2"
                           class="shadow-sm focus:ring-blue-500 focus:border-blue-400 block w-full sm:text-sm border-gray-300 rounded-md"/>
                  </div>
                </div>


                <div class="sm:col-span-3">
                  <label for="education_level" class="block text-sm font-medium text-gray-700">
                    Niveau d'étude *
                  </label>
                  <select v-model="formData.education_level" id="education_level" name="education_level"
                          class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-400 sm:text-sm rounded-md">
                    <option value="N0" name="bac">BAC</option>
                    <option value="N1" name="bac+1">BAC+1</option>
                    <option value="N2" name="bac+2">BAC+2</option>
                    <option value="N3" name="bac+3">BAC+3</option>
                    <option value="N4" name="bac+4">BAC+4</option>
                    <option value="N5" name="bac+5">BAC+5</option>
                    <option value="N7" name="doctorat">Doctorat</option>
                  </select>
                </div>

                <div class="sm:col-span-6">
                  <label class="block text-sm font-medium text-gray-700">Ajouter votre CV *</label>
                  <div v-bind:class="state.file!==''?'ring-2 ring-blue-300':''" class="mt-1 flex justify-center  px-6 pt-5 pb-6 border-2
                  border-gray-300 border-dashed rounded-md rin">
                    <div class="space-y-1 text-center ">
                      <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48"
                           aria-hidden="true">
                        <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      <div class="flex text-sm text-gray-600 ">
                        <label for="file"
                               class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                          <span>Charger votre CV</span>
                          <input id="file" ref="input" @change="changeForm($event.target.files)" name="file" type="file"
                                 required
                                 class="sr-only" accept=".pdf"/>
                        </label>
                      </div>
                      <p v-bind:class="fileName!==''?'text-blue-500':''" class="text-xs text-gray-500">
                        {{
                          fileName === `` ? `PDF up to 10MB` : `Votre CV ${fileName} a bien été charger`
                        }}
                      </p>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <LoaderComponent2 v-if="loadForm" class="m-auto"/>

          <div class="pt-5" v-else>
            <div class="flex justify-end">
              <button v-if="dataDetail.expire === 1" type="reset" @click="fileName = ''"
                      class="bg-white flex flex-row-reverse items-center  py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <div>Vider</div>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                </svg>
              </button>
              <button v-if="dataDetail.expire === 1" type="submit"
                      class="ml-3 flex inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm
                      font-medium rounded-md text-white bg-blue-500 hover:bg-indigo-700
                      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"/>
                </svg>
                <div>
                  Postuler
                </div>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div v-if="dataDetail !== null" v-bind:style="{background:'#191919'}" class="h-16 flex justify-center items-center">
      <h3 class="text-white text-center ">Copyright 2022 Nexah ®</h3>
    </div>

    <div v-if="showDialog" class="fixed inset-0">
      <DialogCard v-on:clickNeg="clickNegDialog"
                  :description="descriptionDialog"
                  :clickPositif="'Okay'"
                  :message="messageDialog"
                  class="mt-20 mx-auto"/>
    </div>

    <div v-if="showDialog2" class="fixed inset-0">
      <DialogCard v-on:clickNeg="clickNegDialog"
                  :message="'Bientôt Disponible'"
                  class="mt-20 mx-auto"/>
    </div>

  </div>
</template>

<script>
import {reactive, ref} from "vue";
import axios from "axios";
import LoaderComponent from "@/components/LoaderComponent";
import LoaderComponent2 from "@/components/LoaderComponent2";
import HeaderComponent from "@/components/HeaderComponent";
import DialogCard from "@/components/DialogCard";

export default {
  name: "detailOffre",
  setup() {
    const state = reactive({
      formData: {},
      file: ''
    })

    return {
      state,
    }
  },

  data() {
    return {
      messageDialog: 'Candidature envoyée',
      descriptionDialog: 'Votre candidature a été soumise avec succèss. Vous serez contactez si elle est retenu',
      formData: {
        "name": "",
        "age": "",
        "sexe": '',
        "phone": "",
        "phone2": "",
        "email": "",
        "last_name": "",
        "experience_year": "",
        "town": "",
        "education_level": "bac+1",
        "file": "",
        "offers_id": "",
        "file_name": "",
        "file_extension": ""
      },
      file: null,
      fileName: '',
      monfichier: null,
      showDialog: false,
      showDialog2: false,
      dataDetail: null,
      listMission: null,
      listProfil: null,
      listCompetence: null,
      loadForm: false
    }
  },
  mounted() {
    var Base64 = {
      _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=", encode: function (e) {
        var t = "";
        var n, r, i, s, o, u, a;
        var f = 0;
        e = Base64._utf8_encode(e);
        while (f < e.length) {
          n = e.charCodeAt(f++);
          r = e.charCodeAt(f++);
          i = e.charCodeAt(f++);
          s = n >> 2;
          o = (n & 3) << 4 | r >> 4;
          u = (r & 15) << 2 | i >> 6;
          a = i & 63;
          if (isNaN(r)) {
            u = a = 64
          } else if (isNaN(i)) {
            a = 64
          }
          t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a)
        }
        return t
      }, decode: function (e) {
        var t = "";
        var n, r, i;
        var s, o, u, a;
        var f = 0;
        e = e.replace(/[^A-Za-z0-9\+\/\=]/g, "");
        while (f < e.length) {
          s = this._keyStr.indexOf(e.charAt(f++));
          o = this._keyStr.indexOf(e.charAt(f++));
          u = this._keyStr.indexOf(e.charAt(f++));
          a = this._keyStr.indexOf(e.charAt(f++));
          n = s << 2 | o >> 4;
          r = (o & 15) << 4 | u >> 2;
          i = (u & 3) << 6 | a;
          t = t + String.fromCharCode(n);
          if (u != 64) {
            t = t + String.fromCharCode(r)
          }
          if (a != 64) {
            t = t + String.fromCharCode(i)
          }
        }
        t = Base64._utf8_decode(t);
        return t
      }, _utf8_encode: function (e) {
        e = e.replace(/\r\n/g, "\n");
        var t = "";
        for (var n = 0; n < e.length; n++) {
          var r = e.charCodeAt(n);
          if (r < 128) {
            t += String.fromCharCode(r)
          } else if (r > 127 && r < 2048) {
            t += String.fromCharCode(r >> 6 | 192);
            t += String.fromCharCode(r & 63 | 128)
          } else {
            t += String.fromCharCode(r >> 12 | 224);
            t += String.fromCharCode(r >> 6 & 63 | 128);
            t += String.fromCharCode(r & 63 | 128)
          }
        }
        return t
      }, _utf8_decode: function (e) {
        var t = "";
        var n = 0;
        var r = c1 = c2 = 0;
        while (n < e.length) {
          r = e.charCodeAt(n);
          if (r < 128) {
            t += String.fromCharCode(r);
            n++
          } else if (r > 191 && r < 224) {
            c2 = e.charCodeAt(n + 1);
            t += String.fromCharCode((r & 31) << 6 | c2 & 63);
            n += 2
          } else {
            c2 = e.charCodeAt(n + 1);
            c3 = e.charCodeAt(n + 2);
            t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63);
            n += 3
          }
        }
        return t
      }
    }
    axios.get("https://ms-careers.nexah.net/api/v1/offer/" + this.$route.params.id, {
      headers: {
        "Authorization": Base64.encode("9:NEX@H")
      }
    })
        .then((response) => {
          this.dataDetail = response.data.data
          this.listMission = this.dataDetail.detail_mission.split('|')
          this.listProfil = this.dataDetail.profile.split('|')
          this.listCompetence = this.dataDetail.skill.split('|')
        }).catch((error) => {
      console.log(error.message)
    })
  },
  methods: {
    cleanForm() {
      this.formData.name = ""
      this.formData.age = ""
      this.formData.phone = ""
      this.formData.email = ""
      this.formData.last_name = ""
      this.formData.experience_year = ""
      this.formData.town = ""
      this.formData.file = ""
      this.formData.offers_id = ""
      this.formData.phone2 = ""
      this.formData.education_level = ""
      this.formData.file_name = ""
      this.formData.file_extension = ""
    },
    changeForm(fileList) {
      this.fileName = fileList[0].name
      var ext = fileList[0].type.split('/')[1]
      console.log(fileList[0])
      this.formData['file_name'] = fileList[0].name.split('.')[0]
      // console.log(this.formData.file_name)
      this.formData['file_extension'] = ext
      var reader = new FileReader()
      reader.readAsDataURL(fileList[0])
      reader.onload = () => {
        // console.log(reader.result)
        this.file = reader.result
      }
      reader.onerror = (error) => {
        console.log('error : ', error)
      }

    },
    clickNegDialog(e) {
      this.showDialog = e
      this.showDialog2 = e
    },
    submit(e) {
      this.loadForm = true
      this.formData['offers_id'] = 2

      const test = {
        "name": this.formData.name,
        "age": this.formData.age,
        "sexe": this.formData.sexe,
        "last_name": this.formData.last_name,
        "phone": this.formData.phone,
        "email": this.formData.email,
        "experience_year": this.formData.experience_year,
        "town": this.formData.town,
        "education_level": this.formData.education_level,
        "file": this.file,
        "phone2": this.formData.phone2,
        "offers_id": this.$route.params.id,
        "file_name": this.formData.file_name,
        "file_extension": this.formData.file_extension
      }
      var Base64 = {
        _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=", encode: function (e) {
          var t = "";
          var n, r, i, s, o, u, a;
          var f = 0;
          e = Base64._utf8_encode(e);
          while (f < e.length) {
            n = e.charCodeAt(f++);
            r = e.charCodeAt(f++);
            i = e.charCodeAt(f++);
            s = n >> 2;
            o = (n & 3) << 4 | r >> 4;
            u = (r & 15) << 2 | i >> 6;
            a = i & 63;
            if (isNaN(r)) {
              u = a = 64
            } else if (isNaN(i)) {
              a = 64
            }
            t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a)
          }
          return t
        }, decode: function (e) {
          var t = "";
          var n, r, i;
          var s, o, u, a;
          var f = 0;
          e = e.replace(/[^A-Za-z0-9\+\/\=]/g, "");
          while (f < e.length) {
            s = this._keyStr.indexOf(e.charAt(f++));
            o = this._keyStr.indexOf(e.charAt(f++));
            u = this._keyStr.indexOf(e.charAt(f++));
            a = this._keyStr.indexOf(e.charAt(f++));
            n = s << 2 | o >> 4;
            r = (o & 15) << 4 | u >> 2;
            i = (u & 3) << 6 | a;
            t = t + String.fromCharCode(n);
            if (u != 64) {
              t = t + String.fromCharCode(r)
            }
            if (a != 64) {
              t = t + String.fromCharCode(i)
            }
          }
          t = Base64._utf8_decode(t);
          return t
        }, _utf8_encode: function (e) {
          e = e.replace(/\r\n/g, "\n");
          var t = "";
          for (var n = 0; n < e.length; n++) {
            var r = e.charCodeAt(n);
            if (r < 128) {
              t += String.fromCharCode(r)
            } else if (r > 127 && r < 2048) {
              t += String.fromCharCode(r >> 6 | 192);
              t += String.fromCharCode(r & 63 | 128)
            } else {
              t += String.fromCharCode(r >> 12 | 224);
              t += String.fromCharCode(r >> 6 & 63 | 128);
              t += String.fromCharCode(r & 63 | 128)
            }
          }
          return t
        }, _utf8_decode: function (e) {
          var t = "";
          var n = 0;
          var r = c1 = c2 = 0;
          while (n < e.length) {
            r = e.charCodeAt(n);
            if (r < 128) {
              t += String.fromCharCode(r);
              n++
            } else if (r > 191 && r < 224) {
              c2 = e.charCodeAt(n + 1);
              t += String.fromCharCode((r & 31) << 6 | c2 & 63);
              n += 2
            } else {
              c2 = e.charCodeAt(n + 1);
              c3 = e.charCodeAt(n + 2);
              t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63);
              n += 3
            }
          }
          return t
        }
      }

      axios.post('https://ms-careers.nexah.net/api/v1/create-profile',
          test, {
            headers: {
              'Authorization': Base64.encode("9:NEX@H"),
              'Content-Type': 'application/json',
            }
          }).then(response => {
        this.messageDialog = 'Candidature envoyée'
        this.descriptionDialog = 'Votre candidature a été soumise avec succèss. Vous serez contactez si elle est retenu'
        this.showDialog = true
        this.loadForm = false
        this.cleanForm()
      }).catch(error => {
        this.messageDialog = "Candidature déjà soumise"
        this.descriptionDialog = "Votre candidature a déjà été prise en compte pour cette offre, Visiter d'autres offres !"
        this.showDialog = true
        this.loadForm = false
      })

    }
  },
  components: {
    LoaderComponent,
    LoaderComponent2,
    HeaderComponent,
    DialogCard
  }
}
</script>

<style scoped>
.fontnexah {
  background-image: url("../assets/fontnexah.png");
  background-position: center;
  background-size: cover;
}
</style>
